<template>
  <!-- 面包屑 -->
  <a-breadcrumb>
    <a-breadcrumb-item>推广管理</a-breadcrumb-item>
    <a-breadcrumb-item>推广链接</a-breadcrumb-item>
  </a-breadcrumb>
  <!-- 消息提示 -->
  <div class="alert-view">
    <a-alert
      type="warning"
      show-icon
    >
      <template #message>
        今日数据更新于 {{ lastTime ? dayjs(lastTime).format('HH:mm:ss') : '00:00:00' }}
        <a style="margin-left: 10px;" @click="refreshData">点击更新数据</a>
        <loading-outlined v-if="isRefreshData" style="margin-left: 6px;" />
      </template>
    </a-alert>
  </div>
  <!-- 功能条 -->
  <div class="tool-view">
    <a-row class="tool-row">
      <!-- 推广： -->
      <a-col :span="6">
        <a-row>
          <a-col :span="6" class="tool-title">推广：</a-col>
          <a-col :span="18">
            <a-input v-model:value="search" class="tool-item" placeholder="请输入ID或名称" />
          </a-col>
        </a-row>
      </a-col>
      <!-- 推广渠道： -->
      <a-col :span="6">
        <a-row>
          <a-col :span="6" class="tool-title">推广渠道：</a-col>
          <a-col :span="18">
            <a-select v-model:value="channelType" class="tool-item" placeholder="请选择">
              <a-select-option :value="undefined">全部</a-select-option>
              <a-select-option
                v-for="item in ChannelTypes"
                :key="item.value"
                :value="item.value"
              >
                {{ item.text }}
              </a-select-option>
            </a-select>
          </a-col>
        </a-row>
      </a-col>
      <!-- 短剧： -->
      <a-col :span="6">
        <a-row>
          <a-col :span="6" class="tool-title">短剧：</a-col>
          <a-col :span="18">
            <a-select
              class="tool-item"
              show-search
              v-model:value="dramaID"
              placeholder="请选择"
              :filter-option="filterOption"
            >
              <a-select-option
                v-for="item in dramas"
                :key="`${item.project_drama_id}-${item.remark_name}`"
                :value="item.project_drama_id"
              >
                {{ item.remark_name }}
              </a-select-option>
            </a-select>
          </a-col>
        </a-row>
      </a-col>
      <!-- 染色日期： -->
      <a-col :span="6">
        <a-row>
          <a-col :span="6" class="tool-title">染色日期：</a-col>
          <a-col :span="18">
            <a-date-picker
              class="tool-item"
              v-model:value="dateRange"
              :format="dateFormat"
            />
          </a-col>
        </a-row>
      </a-col>
      <!-- 操作 -->
      <a-col :span="6">
        <a-row>
          <a-col :span="6" class="tool-title"></a-col>
          <a-col :span="18" style="white-space: nowrap;">
            <a-button type="primary" @click="handleSearch">查询</a-button>
            <a-button style="margin-left: 16px" @click="handleReset">重置</a-button>
          </a-col>
        </a-row>
      </a-col>
      <!-- 操作 -->
      <a-col :span="6">
        <a-row>
          <a-col :span="6" class="tool-title"></a-col>
          <a-col :span="18" style="white-space: nowrap; text-align: right;">
            <!-- 导出 -->
            <a-button @click="touchDownload()" style="margin: 0 10px;">导出数据</a-button>
            <!-- 新建 -->
            <a-button type="primary" @click="touchEdit()">
              <plus-outlined />
              新建
            </a-button>
          </a-col>
        </a-row>
      </a-col>
    </a-row>
  </div>
  <div class="list-view">
    <!-- 列表 -->
    <a-table
      class="table-view"
      :data-source="dataSource"
      :scroll="{ x: scrollX, y: 'max-content' }"
      :columns="columns"
      :row-key="record => record.id"
      :pagination="pagination"
      :loading="isLoading"
      @change="handleTableChange"
    >
      <template #headerCell="{ column }">
        <template v-if="column.key === 'callback_rule_name'">
          <!-- 设置列 -->
          <div style="display: flex; align-items: center; ">
            <!-- 列名 -->
            <span>{{ column.title }}</span>
            <div style="flex: 1"></div>
            <!-- 眼睛 -->
            <EyeOutlined style="cursor: pointer;" @click="() => isVisibleCallbackRule = false" v-if="isVisibleCallbackRule" />
            <EyeInvisibleOutlined style="cursor: pointer;" @click="() => isVisibleCallbackRule = true" v-else />
          </div>
        </template>
        <template v-else-if="column.key === 'operation'">
          <!-- 设置列 -->
          <div style="display: flex; align-items: center;">
            <!-- 列名 -->
            <span>{{ column.title }}</span>
            <div style="flex: 1"></div>
            <!-- 下拉菜单 -->
            <a-dropdown v-model:visible="visible">
              <setting-outlined style="cursor: pointer;" @click.prevent/>
              <template #overlay>
                <a-menu>
                  <a-menu-item>自定义列</a-menu-item>
                  <a-menu-item v-for="item in cusColumns" :key="item.key"><a-checkbox v-model:checked="item.status">{{ item.title }}</a-checkbox></a-menu-item>
                  <a-menu-item>
                    <a-button
                      type="primary"
                      style="width: 100%;"
                      size="small"
                      @click="touchMenuSave"
                    >保存</a-button>
                  </a-menu-item>
                </a-menu>
              </template>
            </a-dropdown>
          </div>
        </template>
        <template v-else>
          <!-- 其他列 -->
          <a-tooltip v-if="column.msg">
            <template #title>{{ column.msg }}</template>
            {{ column.title }}
          </a-tooltip>
          <span v-else>{{ column.title }}</span>
        </template>
      </template>
      <!-- 自定义行内容 -->
      <template #bodyCell="{ column, record }">
        <!-- 短剧 -->
        <template v-if="column.key === 'drama_remark_name'">
          {{ record[column.key] || '-' }}
        </template>
        <!-- 付费起始 -->
        <template v-if="column.key === 'start_pay_eq'">
          {{ record.start_pay_eq || '-' }}
        </template>
        <!-- 每集收费 -->
        <template v-if="column.key === 'fee'">
          {{ record[column.key] || '-' }}
        </template>
        <!-- 金额类型 -->
        <template v-if="column.key === 'money_type'">
          {{ (RechargeTemplateTypes.find(item => item.value === record.money_type) || {}).text }}
        </template>
        <!-- 充值回传 -->
        <template v-if="column.key === 'callback_rule_name'">
          <a-tooltip v-if="isVisibleCallbackRule" :visible="true" placement="right">
            <template #title>{{ record.callback_rule_name }}</template>
            {{ (CallbackTypes.find(item => item.value === record.callback_type) || {}).text }}
          </a-tooltip>
          <a-tooltip v-else placement="right">
            <template #title>{{ record.callback_rule_name }}</template>
            {{ (CallbackTypes.find(item => item.value === record.callback_type) || {}).text }}
          </a-tooltip>
        </template>
        <!-- 客户端 -->
        <template v-if="column.key === 'client'">
          {{ (ClientTypes.find(item => item.value === record.client) || {}).text }}
        </template>
        <!-- 推广渠道 -->
        <template v-if="column.key === 'channel'">
          {{ (ChannelTypes.find(item => item.value === record.channel) || {}).text }}
        </template>
        <!-- 当日访问用户 -->
        <template v-if="column.key === 'visit_user_count' || column.key === 'first_pay_user_count'">
          {{ record[column.key] || '-' }}
        </template>
        <!-- 金额 -->
        <template v-if="column.key === 'pay_amount' || column.key === 'first_pay_money' || column.key === 'total_pay_money' || column.key === 'first_pay_money' || column.key === 'total_pay_money'">
          {{ record[column.key] ? $pub.KEEP_NUMBER_DECIMAL((record[column.key] || 0) / 100, 2, false, true) : '-' }}
        </template>
        <!-- 首日arpu值 -->
        <template v-if="column.key === 'first_arpu' || column.key === 'total_arpu'">
          {{ record[column.key] ? $pub.KEEP_NUMBER_DECIMAL((record[column.key] || 0) / 100, 2, false, true) : '-' }}
        </template>
        <!-- 百分比 -->
        <template v-if="column.key === 'total_pay_user_count_rate' || column.key === 'total_repeat_pay_user_count_rate' || column.key === 'pay_completed_rate'">
          {{ record[column.key] ? `${record[column.key]}%` : '-' }}
        </template>
        <!-- 操作 -->
        <template v-if="column.key === 'operation'">
          <a class="operation-item" @click="touchEdit(record)">编辑</a>
          <a class="operation-item" @click="touchEditCopy(record)">复制创建</a>
          <a-dropdown>
            <a @click.prevent>
              更多 <DownOutlined />
            </a>
            <template #overlay>
              <a-menu>
                <!-- 复制链接 -->
                <a-menu-item>
                  <a class="operation-item" @click="touchCopy(record)">复制链接</a>
                </a-menu-item>
                <!-- 优酷 + 微信 -->
                <a-menu-item v-if="(record.channel === 13 && record.client === 1)">
                  <a class="operation-item" @click="touchCopy2(record)">复制页面</a>
                </a-menu-item>
                <a-menu-item v-if="(record.channel === 13 && record.client === 1)">
                  <a class="operation-item" @click="touchMonitor(record)">复制监测链接</a>
                </a-menu-item>
                <!-- 巨量 + 抖音 -->
                <a-menu-item v-if="(record.channel === 1 && record.client === 2)">
                  <a class="operation-item" @click="touchPage(record)">复制页面</a>
                </a-menu-item>
                <a-menu-item v-if="(record.channel === 1 && record.client === 2)">
                  <a class="operation-item" @click="touchParams(record)">复制参数</a>
                </a-menu-item>
                <!-- 巨量 + H5 -->
                <a-menu-item v-if="(record.channel === 1 && record.client === 5)">
                  <a class="operation-item" @click="touchMonitor(record)">复制监测链接</a>
                </a-menu-item>
                <!-- Vivo + 快应用 -->
                <a-menu-item v-if="(record.channel === 7 && record.client === 4)">
                  <a class="operation-item" @click="touchMonitor(record)">复制监测链接</a>
                </a-menu-item>
                <!-- Wifi + 微信 -->
                <a-menu-item v-if="(record.channel === 16 && record.client === 1)">
                  <a class="operation-item" @click="touchMonitor(record)">复制监测链接</a>
                </a-menu-item>
                <!-- UC + 微信 -->
                <a-menu-item v-if="(record.channel === 17 && record.client === 1)">
                  <a class="operation-item" @click="touchMonitor(record)">复制接口地址</a>
                </a-menu-item>
                <!-- 磁力 + 快手 -->
                <a-menu-item v-if="(record.channel === 8 && record.client === 3)">
                  <a class="operation-item" @click="touchPage(record)">复制页面</a>
                </a-menu-item>
                <a-menu-item v-if="(record.channel === 8 && record.client === 3)">
                  <a class="operation-item" @click="touchParams(record)">复制参数</a>
                </a-menu-item>
                <a-menu-item v-if="(record.channel === 8 && record.client === 3)">
                  <a class="operation-item" @click="touchMonitor(record)">复制监测链接</a>
                </a-menu-item>
                <!-- oppo + 快应用 -->
                <a-menu-item v-if="(record.channel === 5 && record.client === 4)">
                  <a class="operation-item" @click="touchMonitor(record)">复制监测链接</a>
                </a-menu-item>
                <!-- oppo + 快应用 -->
                <a-menu-item v-if="(record.channel === 5 && record.client === 4)">
                  <a class="operation-item" @click="touchFail(record)">复制失败地址</a>
                </a-menu-item>
                <!-- 每日数据 -->
                <a-menu-item>
                  <a class="operation-item" @click="touchDetail(record)">每日数据</a>
                </a-menu-item>
              </a-menu>
            </template>
          </a-dropdown>
        </template>
      </template>
    </a-table>
    <div class="list-hint">首日充值金额共计 {{ $pub.KEEP_NUMBER_DECIMAL((sum_first_pay_money || 0) / 100, 2, false, true) }} 元，累计充值金额共计 {{ $pub.KEEP_NUMBER_DECIMAL((sum_total_pay_money || 0) / 100, 2, false, true) }} 元</div>
  </div>
  <!-- 数据详情 -->
  <DataDrawer ref="RefDataDrawer"></DataDrawer>
  <!-- 新建 -->
  <NewDrawer ref="RefNewDrawer" @success="handleSearch"></NewDrawer>
</template>

<script setup>
import { onBeforeMount, ref, reactive, getCurrentInstance } from 'vue'
import { useRoute } from 'vue-router'
import { message } from 'ant-design-vue'
import { ChannelTypes, ClientTypes, RechargeTemplateTypes, CallbackTypes } from '@/utils/constantList'
import dayjs from 'dayjs'
import Pub from '@/utils/public'
import { advList, advRefreshTime, advRefreshData } from '@/api/promotion'
import { 
  projectDramaAll,
  popularizeMenuDetail,
  popularizeMenuSave
} from '@/api/operate'
import DataDrawer from './components-link/DataDrawer.vue'
import NewDrawer from './components-link/NewDrawer.vue'
import * as Ex from "@netjoy/excelex"

// 数据详情
let RefDataDrawer = ref(null)
// 新增
let RefNewDrawer = ref(null)
// 加载
let isLoading = ref(false)
// 自定义列菜单
let visible = ref(false)
// 更新数据
let isRefreshData = ref(false)
// 是否显示充值回传详情
let isVisibleCallbackRule = ref(false)
// 日期format
const dateFormat = 'YYYY-MM-DD'
// 日期范围
let dateRange = ref(dayjs())
// 推广渠道
let channelType = ref(undefined)
// 短剧列表
let dramas = ref([])
// 短剧ID
let dramaID = ref(undefined)
// 搜索
let search = ref(undefined)
// 刷新时间
let lastTime = ref(undefined)
// 数据源
let dataSource = ref([])
// 首日充值金额
let sum_first_pay_money = ref(0)
// 累计充值金额
let sum_total_pay_money = ref(0)
// 排序字段
let order = ref('first_pay_money')
// 排序顺序
let orderType = ref('desc')
// 分页信息
let pagination = reactive({
  total: 0,
  current: 1,
  pageSize: 10,
  showSizeChanger: true
})
// 滚动范围
let scrollX = ref(0)
// 列数据源
let columns = ref([])
// 默认列数据源
let defColumns = ref([
  {
    title: '推广ID',
    dataIndex: 'id',
    key: 'id',
    fixed: 'left',
    width: 100,
    sorter: true
  },
  {
    title: '推广链接名称',
    dataIndex: 'name',
    fixed: 'left',
    width: 200,
    key: 'name',
  },
  {
    title: '操作',
    dataIndex: 'operation',
    key: 'operation',
    width: 200,
    fixed: 'right',
  }
])
// 自定义列数据源
let cusColumns = ref([
  {
    title: '创建人',
    dataIndex: 'create_username',
    key: 'create_username',
    width: 200,
  },
  {
    title: '短剧',
    dataIndex: 'drama_remark_name',
    key: 'drama_remark_name',
    width: 200,
  },
  {
    title: '模板类型',
    dataIndex: 'money_type',
    key: 'money_type',
    width: 100,
  },
  {
    title: '付费起始',
    dataIndex: 'start_pay_eq',
    key: 'start_pay_eq',
    width: 100,
  },
  {
    title: '每集收费',
    dataIndex: 'fee',
    key: 'fee',
    width: 100,
  },
  {
    title: '充值回传',
    dataIndex: 'callback_rule_name',
    key: 'callback_rule_name',
    width: 180,
  },
  {
    title: '客户端',
    dataIndex: 'client',
    key: 'client',
    width: 120,
  },
  {
    title: '推广渠道',
    dataIndex: 'channel',
    key: 'channel',
    width: 100,
  },
  {
    title: '访问用户',
    dataIndex: 'visit_user_count',
    key: 'visit_user_count',
    msg: '当前日期通过推广链接进入的用户数',
    showSorterTooltip: false,
    width: 100,
    sorter: true,
    sortDirections: ['descend', 'ascend']
  },
  {
    title: '首日充值用户',
    dataIndex: 'first_pay_user_count',
    key: 'first_pay_user_count',
    msg: '在推广日当天产生付费的用户数',
    showSorterTooltip: false,
    width: 100,
    sorter: true,
    sortDirections: ['descend', 'ascend']
  },
  {
    title: '首日充值金额',
    dataIndex: 'first_pay_money',
    key: 'first_pay_money',
    msg: '在推广日当天产生的充值金额',
    showSorterTooltip: false,
    width: 100,
    sorter: true,
    sortDirections: ['descend', 'ascend'],
    defaultSortOrder: 'descend'
  },
  {
    title: '首日arpu值',
    dataIndex: 'first_arpu',
    key: 'first_arpu',
    msg: '首日充值金额/首日充值用户',
    showSorterTooltip: false,
    width: 100,
    sorter: true,
    sortDirections: ['descend', 'ascend']
  },
  // {
  //   title: '今日充值金额',
  //   dataIndex: 'pay_amount',
  //   key: 'pay_amount',
  //   msg: '当前日期通过推广链接进入的用户在今天产生的充值金额',
  //   showSorterTooltip: false,
  //   width: 100,
  //   sorter: true,
  //   sortDirections: ['descend', 'ascend']
  // },
  {
    title: '累计充值金额',
    dataIndex: 'total_pay_money',
    key: 'total_pay_money',
    msg: '当前日期通过推广链接进入的用户累计产生的充值金额（包括第二天、第三天...的续充）',
    showSorterTooltip: false,
    width: 100,
    sorter: true,
    sortDirections: ['descend', 'ascend']
  },
  {
    title: '累计arpu值',
    dataIndex: 'total_arpu',
    key: 'total_arpu',
    msg: '累计充值金额/累计充值用户',
    showSorterTooltip: false,
    width: 100,
    sorter: true,
    sortDirections: ['descend', 'ascend']
  },
  {
    title: '充值率',
    dataIndex: 'total_pay_user_count_rate',
    key: 'total_pay_user_count_rate',
    msg: '累计充值用户/访问用户',
    showSorterTooltip: false,
    width: 100,
    sorter: true,
    sortDirections: ['descend', 'ascend']
  },
  {
    title: '复充率',
    dataIndex: 'total_repeat_pay_user_count_rate',
    key: 'total_repeat_pay_user_count_rate',
    msg: '复充率=复充用户数/充值用户数',
    showSorterTooltip: false,
    width: 100,
    sorter: true,
    sortDirections: ['descend', 'ascend']
  },
  // {
  //   title: '支付完成率',
  //   dataIndex: 'pay_completed_rate',
  //   key: 'pay_completed_rate',
  //   msg: '支付完成率=支付完成订单数/生成订单数',
  //   showSorterTooltip: false,
  //   width: 100
  // },
  // {
  //   title: '推广费用',
  //   dataIndex: 'total_amount',
  //   key: 'total_amount'
  // },
  // {
  //   title: '当日访问用户',
  //   dataIndex: 'visit_user_count',
  //   key: 'visit_user_count',
  //   sorter: true
  // },
  // {
  //   title: '今日充值',
  //   dataIndex: 'pay_amount',
  //   key: 'pay_amount',
  //   sorter: true,
  //   defaultSortOrder: 'descend'
  // },
  // {
  //   title: '当日ROI',
  //   dataIndex: 'trade_no',
  //   key: 'trade_no'
  // },
])

// 滚动X
function getScrollX () {
  var x = 0
  columns.value.forEach(item => {
    x += item.width
  })
}

// 钩子函数
onBeforeMount (() => {
  // query带入uid字段
  const route = useRoute()
  if (route.query.id) {
    search.value = route.query.id
  }
  // 获取刷新时间
  getRefreshTime()
  // 获取短剧列表
  getProjectDramaAll()
  // 获取自定义列
  getMenuDetail()
})

// 获取自定列列表
function getMenuDetail () {
  popularizeMenuDetail().then(res => {
    const { code, data } = res
    if (code === 0) {
      cusColumns.value.forEach(item => {
        item.status = Boolean(data[item.key])
      })
      // 刷新列
      reloadColumns()
      // 获取列表
      getAdvList()
    }
  })
}

// 刷新列数据
function reloadColumns () {
  const newColumns = cusColumns.value.filter(item => {
    return item.status
  })
  columns.value = [defColumns.value[0], defColumns.value[1], ...newColumns, defColumns.value[2]]
  getScrollX()
}

// 保存自定列列表
function touchMenuSave () {
  visible.value = false
  reloadColumns()
  const params = {}
  cusColumns.value.filter(item => {
    params[item.key] = item.status ? 1 : 0
  })
  popularizeMenuSave(params).then(res => {
    const { code } = res
    if (code === 0) {
      message.success('保存成功')
    } else {
      message.error(res.message || '保存失败')
    }
  }).catch(() => {
    message.error('保存失败')
  })
}

// 短剧列表
function getProjectDramaAll () {
  projectDramaAll().then(res => {
    const { code, data } = res
    if (code === 0) {
      dramas.value = data
    }
  })
}

// 数据详情
function touchDetail (record) {
  RefDataDrawer.value.showDrawer(record)
}

// 新建
function touchEdit (record) {
  RefNewDrawer.value.showDrawer(record || {})
}

// 复制创建
function touchEditCopy (record) {
  RefNewDrawer.value.showDrawer(record || {}, true)
}

// 复制链接
function touchCopy (record) {
  // 落地页
  var url = Pub.LANDING_URL(record)
  Pub.COPY(url)
}

// 复制链接
function touchCopy2 (record) {
  // 落地页
  var url = Pub.LANDING_URL(record, false)
  Pub.COPY(url)
}

// 复制页面
function touchPage (record) {
  // 拷贝
  const page = Pub.LANDING_URL(record, false).split('?')[0]
  Pub.COPY(page)
}

// 复制参数
function touchParams (record) {
  // 拷贝
  const params = Pub.LANDING_URL(record, false).split('?')[1]
  Pub.COPY(params)
}
// 复制监测链接
function touchMonitor (record) {
  // 拷贝
  Pub.COPY(record.checkLinkUrl)
}

// 复制失败链接
function touchFail (record) {
  // 拷贝
  Pub.COPY(Pub.FAIL_URL(record))
}

// 查询
function handleSearch () {
  // 设置分页
  pagination.current = 1
  // 获取列表
  getAdvList()
}

// 分页处理
function handleTableChange (p, filters, sorter) {
  pagination.current = p.current
  pagination.pageSize = p.pageSize
  if (sorter.columnKey) {
    order.value = sorter.columnKey
    orderType.value = sorter.order === 'descend' ? 'desc' : 'asc'
  }
  getAdvList()
}

// 重置
function handleReset () {
  // 筛选项重置
  dateRange.value = dayjs()
  search.value = undefined
  channelType.value = undefined
  dramaID.value = undefined
  // 分页重置
  pagination.current = 1
  getAdvList()
}

// 获取刷新时间
function getRefreshTime () {
  advRefreshTime().then(res => {
    const { code, data } = res
    if (code === 0) {
      lastTime.value = data.lastTime
    }
  })
}

// 刷新接口
function refreshData () {
  if (isRefreshData.value) { return }
  isRefreshData.value = true
  advRefreshData().then(res => {
    const { code, data } = res
    if (code === 0) {
      lastTime.value = data.lastTime
      message.success('更新成功')
      handleSearch()
    }
    isRefreshData.value = false
  }).catch(() => {
    isRefreshData.value = false
  })
}

// 模糊匹配
function filterOption (input, option) {
  return `${option.key}`.toLowerCase().includes(`${input || ''}`.toLowerCase())
}

// 获取列表
function getAdvList () {
  isLoading.value = true
  const params = {
    date: dateRange.value ? `${dateRange.value.format(dateFormat)}` : undefined,
    keyword: search.value,
    channel: channelType.value,
    project_drama_id: dramaID.value,
    order: order.value,
    sort: orderType.value,
    page: pagination.current,
    page_size: pagination.pageSize
  }
  advList(params).then(res => {
    isLoading.value = false
    const { code, data, msg } = res
    if (code === 0) {
      dataSource.value = data.data
      pagination.total = data.total
      sum_first_pay_money.value = data.sum_first_pay_money
      sum_total_pay_money.value = data.sum_total_pay_money
    } else {
      message.error(res.message || msg)
    }
  })
}

// 下载
function touchDownload () {
  isLoading.value = true
  const params = {
    date: dateRange.value ? `${dateRange.value.format(dateFormat)}` : undefined,
    keyword: search.value,
    channel: channelType.value,
    project_drama_id: dramaID.value,
    order: order.value,
    sort: orderType.value,
    page: 1,
    page_size: 10000
  }
  advList(params).then(res => {
    isLoading.value = false
    const { code, data, msg } = res
    if (code === 0) {
      const list = data.data
      if (list.length) {
        const tablecolumns = []
        columns.value.forEach(item => {
          if (item.key !== 'operation') {
            tablecolumns.push({
              name: item.title,
              field: item.key
            })
          }
        })
        const sheets = [
          {
            name: '推广链接',
            data: list,
            columns: tablecolumns
          }
        ]
        Ex.ex(sheets, function (item, field, json, sheetIndex, row, col, rowCount, colCount) {
          // row=0 为表头
          if (row != 0) {
            if (field === 'money_type') {
              item.data = (RechargeTemplateTypes.find(item => item.value === json[field]) || {}).text || '-'
            } else if (field === 'callback_rule_name') {
              item.data = `${(CallbackTypes.find(item => item.value === json.callback_type) || {}).text || '-'}${item.data ? `【${item.data}】` : ''}`
            } else if (field === 'client') {
              item.data = (ClientTypes.find(item => item.value === json[field]) || {}).text || '-'
            } else if (field === 'channel') {
              item.data = (ChannelTypes.find(item => item.value === json[field]) || {}).text || '-'
            } else if (field === 'pay_amount' || field === 'first_pay_money' || field === 'total_pay_money') {
              item.data = Pub.KEEP_NUMBER_DECIMAL(json[field] / 100, 2, false, true)
              return item
            } else if (field === 'first_arpu' || field === 'total_arpu') {
              item.data = Pub.KEEP_NUMBER_DECIMAL(json[field] / 100, 2, false, true)
              return item
            } else if (field === 'total_pay_user_count_rate' || field === 'total_repeat_pay_user_count_rate' || field === 'pay_completed_rate') {
              item.data = `${json[field] || 0}%`
              return item
            } else {
              item.data = json[field] || '-'
            }
          }
          return item
        },  `推广代理-推广链接`)
      } else {
        message.error('还没有可导出的内容')
      }
    } else {
      message.error('导出失败')
    }
  }).catch((err) => {
    console.log(err)
    message.error('导出失败')
  })
}
</script>

<style scoped>
.alert-view {
  margin-top: 20px;
}
.tool-view {
  display: flex;
  align-items: center;
  margin-top: 20px;
}
.table-view {
  margin-top: 30px;
}
.tool-title:not(:first-child) {
  margin-left: 16px;
}

.list-view {
  position: relative;
  .list-hint {
    position: absolute;
    bottom: 14px;
  }
}
</style>